import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import ContactSection from "../components/ContactSection";
import {getImage} from "gatsby-plugin-image";

// eslint-disable-next-line
export const ContactPageTemplate = ({mapStaticImage}) => {
  const mapImage = getImage(mapStaticImage) || mapStaticImage;

  return (
    <section className="section">
      <div className="container">
        <ContactSection mapImage={mapImage} />
      </div>
    </section>
  );
};

ContactPageTemplate.propTypes = {
  mapStaticImage: PropTypes.object,
};

const ContactPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <ContactPageTemplate mapStaticImage={frontmatter.mapStaticImage} />
    </Layout>
  );
};

ContactPage.propTypes = {
  mapStaticImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default ContactPage;

export const pageQuery = graphql`
  query ContactPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
      frontmatter {        
        mapStaticImage {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: CONSTRAINED)
          }
        } 
      }
    }
  }
`
